import React, {useState} from "react";
import Term from "./tnc";

export default function Footer() {
  const [term, setTerm] = useState(false);
  const dateNow = new Date();
  const year = dateNow && dateNow.getFullYear();

  return(
    <>
      <div className="row fatfooter">
        <div className="col fatfooter-container">
          <div className="row footer-row">
            <div className="col-auto">
              <i className="fa fa-copyright"></i>
              {`Cairns Central ${year}`}
              <span> | </span>
              {term && <Term onClose={() => setTerm(false)} />}
              <a onClick={() => setTerm(!term)}>
                {"Terms of Use & Privacy Policy"}
              </a>
              <span> | </span>
              <a href="/esafety" target="_blank" className="esafety-link">{"eSafety"}</a>
            </div>
          </div>
        </div>
      </div>

      <div className="row smallfooter">
        <div className="col smallfooter-container">
          <div className="row smallfooter-row-1">
            <i className="fa fa-copyright"></i> {`Cairns Central ${year}`}    
          </div>
          <div className="row smallfooter-row-2">
            <a onClick={() => setTerm(!term)}>
              {"Terms of Use & Privacy Policy"}
            </a>
            <span> | </span>
            <a href="/esafety" target="_blank" className="esafety-link">{"eSafety"}</a>
          </div>
        </div>
      </div>
    </>
  );
};