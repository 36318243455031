import React from "react";
import Footer from "./subcomponents/footer";
import Header from "./subcomponents/header";

export default function Theme(props) { 

  return (
    <div>
      <div
        className="v-admin-login"
      >
        <div className="container-fluid fh v-login-contents">
          <Header/>
          <div className="row fh-element v-login-body">
            <div className="col v-login-branding-section">
              <p className="v-login-branding-heading">
                {"Welcome to Cairns Central Free Wi-Fi"}
              </p>
              <p className="v-login-branding-subheading">
                {"Cairns Central is located in the heart of Cairns CBD at 1/21"}<br/> {"McLeod Street, providing an effortless shopping experience."}
              </p>
            </div>
            
            <div className="col v-login-form-section">
              <div className="login-form-container">
                <p className="v-login-form-heading">
                  {"Please enter your details below"}
                </p>
                <div className="v-login-form-body">
                  {props.children}
                </div>
              </div>
            </div>
          </div>
          <Footer/>
        </div>
      </div>
    </div>);
}
