import React from "react";

export default function Header() {
  return(
    <>
      <div className="row header"> 
        <div className="col"> 
          <div className="header-contents">
            <a href="https://cairnscentral.com.au/" target="_blank" className="header-link">
              <img className="header-icon" src="/CairnsCentral_Logo.png" alt="cairns-logo-lg"/>
            </a>
          </div>
        </div> 
      </div>

      <div className="row header-small"> 
        <div className="col header-col"> 
          <div className="header-contents">
            <a href="https://cairnscentral.com.au/" target="_blank" className="header-link">
              <img className="header-icon" src="/CairnsCentral_Logo_White.png" alt="cairns-logo-lg"/>
            </a>
          </div>
        </div> 
      </div>  
    </>
  )
}